import React, { Component } from 'react';
import amber_logo_blue from '../../images/amber-logo-blue.png';
import axios from '../../helper/Axios';
import { NotificationManager } from 'react-notifications';
import password_hidden from '../../images/password-hover.svg';
import password_visible from '../../images/password-enabled.svg';
import classnames from 'classnames';
import {looseFocus} from '../../helper/LooseFocus';
import GeneratedemoLinkModal from './GeneratedemoLinkModal';
import map from 'lodash.map';
import { getBaseURL } from '../../helper/getApiBase';

export default class componentName extends Component {

    state = {
        password: '',
        name: '',
        company: '',
        ceo: '',
        selected_stage: 'Select Stage',
        password_flag: true,
        loading: false,
        email: '',
        stages: [],
        stage_map: {},
        chat_email: '',
        chat_token: '',
        is_active: false,
        regions: {
            'us-east-1': 'Rest of the World',
            'eu-west-1': 'Europe'
        },
        regions_reverse: {
            'Rest of the World': 'us-east-1',
            'Europe': 'eu-west-1'
        },
        selected_region: ''
    }

    // component mounts
    // ----------------
    componentDidMount(){
        this.fetchStages();
    }

    // Fetch stages
    // --------------------
    fetchStages = async () => {
        try {
            const response = await axios.get('/exampleStages');
            this.setState({stages: response.data}, () => this.createStageIdMapping());
        } catch (error) {
            if(error.response){
                NotificationManager.error(error.response.data.message || 'Something went wrong');
            } else {
                NotificationManager.error('Something went wrong');
            }
        }
    }

    // XOR helper method
    // -----------------
    keyCharAt(key, i) {
        return key.charCodeAt(Math.floor(i % key.length));
    }

    // XOR encrypt password
    // --------------------
    xor_encrypt(key, data) {
        return map(data, (c, i) => {
            return c.charCodeAt(0) ^ this.keyCharAt(key, i);
        });
    }

    onChangeOfRegion = async (e) => {
        this.setState({ [e.target.name]: e.target.value });
        let {regions_reverse} = this.state;
        let region = regions_reverse[e.target.value];
        sessionStorage.removeItem("current_region_url");
        await getBaseURL(region, "dashboard");
        this.fetchStages();
    }

    // create chat
    // -----------
    createChat = async (e) => {
        e.preventDefault();
        looseFocus();
        const { email, password, name, company, ceo, stage_map, selected_stage, selected_region } = this.state;
        let encrypted_password = this.xor_encrypt('rkjbflaejrbgbjefaljkbewf', password);
        this.setState({loading: true});
        if(selected_stage === 'Select Stage'){
            NotificationManager.info('Select a Stage');
            this.setState({loading: false});
            return;
        }
        if(email.split('@')[1].toLocaleLowerCase() !== 'infeedo.com'){
            NotificationManager.error('Unauthorized');
            this.setState({loading: false});
            return;
        }
        if(selected_region === 'Select Region' || !selected_region.length){
            NotificationManager.error('Region is required');
            this.setState({loading: false});
            return;
        }
        try {
            const response = await axios.post(`/generateDemo/${stage_map[selected_stage]}`, {name, ceo, company, password: encrypted_password, email});
            if(response.status === 200){
                this.setState({chat_email: response.data.email, chat_token: response.data.token}, () => this.openChatLinkModal());
            }
        } catch (error) {
            if(error.response){
                NotificationManager.error(error.response.data.message || 'Something went wrong');
            } else {
                NotificationManager.error('Something went wrong');
            }
        }
        this.setState({loading: false}); 
    }

    // create stage id mapping
    // -----------------------
    createStageIdMapping = () => {
        const {stages} = this.state;
        const stage_map = {};
        stages.forEach(stage => {
            stage_map[`${stage.duration}-${stage.unit}`] = stage.id;
        });
        this.setState({stage_map});
    }

    // On change input values map to state
    // -----------------------------------
    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    // toggle password flag
    // --------------------
    togglePasswordFlag = () => {
        const { password_flag } = this.state;
        this.setState({password_flag: !password_flag});
    }

    // open chat link modal
    // --------------------
    openChatLinkModal = () => {
        this.setState({is_active: true});
    }

    // close chat link modal
    // --------------------
    closeChatLinkModal = () => {
        this.setState({is_active: false, password: '', chat_email: '', chat_token: ''});
    }
    
    render() {
        const { loading, password, name, email, company, ceo, password_flag, stages, selected_stage, chat_email, chat_token, is_active, regions, selected_region } = this.state;
        return (
            <div className="columns pd-25">
                <form className="column is-4 mar-auto" onSubmit={this.createChat}>
                    <figure className="app-logo">
                        <img src={amber_logo_blue} width="130px" alt="amber logo"/>
                    </figure>
                    <h1 className="login-heading">Login Details</h1>
                    <div className="field">
                        <div className="select">
                            <select name="selected_region" value={selected_region} onChange={this.onChangeOfRegion}>
                                <option key="select">Select Region</option>
                                {
                                    Object.keys(regions).map((region, index) => (
                                        <option key={index} id={region}>{regions[region]}</option>
                                    )) 
                                }
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <p className="control">
                            <input onChange={this.onChange} value={email} name="email" className="input" type="email" placeholder="Email Id" id="email" autoFocus required/>
                        </p>
                    </div>
                    <div className="field">
                        <p className="control">
                            <input onChange={this.onChange} value={password} name="password" className="input" type={(password_flag ? 'password': 'text')} placeholder="Password" id="password" required/>
                            <button type="button" className="button is-white is-eye" onClick={this.togglePasswordFlag}>
                                <span><img src={(password_flag ? password_visible : password_hidden)} alt="password"/></span>
                            </button>
                        </p>
                    </div>
                    <hr/>
                    <h1 className="login-heading">Amber Chat Configuration</h1>
                    <div className="field">
                        <p className="control">
                            <input onChange={this.onChange} value={name} name="name" className="input" type="text" placeholder="Employee Name" id="name" required/>
                        </p>
                    </div>
                    <div className="field">
                        <p className="control">
                            <input onChange={this.onChange} value={company} name="company" className="input" type="text" placeholder="Company" id="company" required/>
                        </p>
                    </div>
                    <div className="field">
                        <p className="control">
                            <input onChange={this.onChange} value={ceo} name="ceo" className="input" type="text" placeholder="CEO" id="ceo" required/>
                        </p>
                    </div>
                    <div className="field">
                        <div className="select">
                            <select name="selected_stage" value={selected_stage} onChange={this.onChange}>
                                <option key="select">Select Stage</option>
                                {stages.length ? stages.sort((a,b) => a.unit.localeCompare(b.unit)).map((stage, index) => (
                                    <option key={index} id={stage.id}>{stage.duration}-{stage.unit}</option>
                                )) 
                                : <option key="loading">loading...</option>}
                            </select>
                        </div>
                    </div>
                    <div className="field pd-top-12">
                        <p className="control has-text-right">
                            <button type="submit" className={classnames('button is-link', {'is-loading': loading})} disabled={!(name.length && company.length && ceo.length && password.length && email.length && selected_stage !== 'Select Stage')}>
                            &nbsp;&nbsp;Generate&nbsp;&nbsp;
                            </button>
                        </p>
                    </div>
                    <br/>
                    <br/>
                </form>
                <GeneratedemoLinkModal is_active={is_active} onClose={this.closeChatLinkModal} chat_token={chat_token} chat_email={chat_email}/>
            </div>
        )
    }
}
