import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from "prop-types";
import config from '../../../config';

export default class GeneratedemoLinkModal extends Component {
    render() {
        const { is_active, onClose, chat_token, chat_email } = this.props;
        return (
            <div className={classnames('modal', {'is-active': is_active})}>
                <div className="modal-background"></div>
                <div className="card modal-card pd-20 radius-10 wide-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title mg-10 pd-10">Chat Link</p>
                    </header>
                    <section className="modal-card-body">
                        <p className="mg-bottom-10"><b>Email</b></p>
                        <p>{chat_email} <br/> <span className="help has-text-grey">You can use the following email to access the account in your inbox.</span></p>
                        <br/>
                        <p className="mg-bottom-10"><b>Chat Link</b></p>
                        <p><a href={`${config.chat_url}/#/token:${chat_token}`} className="break-word" target="_blank" rel="noopener noreferrer">{`${config.chat_url}/#/token:${chat_token}`}</a></p>
                    </section>
                </div>
                <button onClick={onClose} className="modal-close is-large" aria-label="close"></button>
            </div>
        )
    }
}

GeneratedemoLinkModal.propTypes = {
    is_active: PropTypes.bool.isRequired,
    chat_token: PropTypes.string.isRequired,
    chat_email: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
}