import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import GenerateDemoChat from "../components/generatedemochat/GenerateDemoChat";

const DemoChat = () => (
  <Layout mode={true} sticky_footer={false}>
    <SEO title="Amber by inFeedo | Chat Generator" />
    <GenerateDemoChat/>
  </Layout>
)

export default DemoChat